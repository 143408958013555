import { FormContext } from '../../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../../utils/state/initialStateFactory';
import { FormErrors } from '../../../../../types/errors';

export type RemoveErrors = (error: FormErrors[]) => void;

export function createRemoveErrorsAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): RemoveErrors {
  return (errorsToRemove: FormErrors[]) => {
    const [state, setState] = getControllerState();
    const { errors: prevFormErrors } = state;

    const errors: FormErrors[] = prevFormErrors.filter(
      (error) => !errorsToRemove.includes(error),
    );
    setState({
      errors,
    });
  };
}
